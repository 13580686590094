export const Arrow = ({ props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.93811 9.4315C7.7821 9.58751 7.89259 9.85427 8.11323 9.85427H17.5141C18.0084 9.85427 18.4091 10.255 18.4091 10.7493C18.4091 11.2435 18.0084 11.6442 17.5141 11.6442H8.11323C7.89259 11.6442 7.7821 11.911 7.93811 12.067L11.6833 15.8122C12.0327 16.1616 12.0327 16.7282 11.6833 17.0777C11.3338 17.4271 10.7672 17.4271 10.4178 17.0777L4.8352 11.4951C4.42328 11.0832 4.42328 10.4153 4.83519 10.0034L10.4178 4.42084C10.7672 4.07138 11.3338 4.07138 11.6833 4.42084C12.0327 4.7703 12.0327 5.33688 11.6833 5.68634L7.93811 9.4315Z"
      fill="#1A1D1F"
    />
  </svg>
)
