import React, { useState } from 'react'
import { QrReader } from 'react-qr-reader'
import Button from '../ui/button/button'
import './qrReader.css'
import { Border } from '../ui/icons/border'

export const QrReaderContainer = ({ handleQr }) => {
  const width = visualViewport.width
  const height = visualViewport.height

  const borderWidthTop = String(Math.round(150)) + 'px'
  const borderWidthBottom = String(height - Math.round(height - 250) / 2.5) + 'px'
  const borderWidth = String(Math.round(width - 250) / 2) + 'px'

  return (
    <div style={{ overflow: 'hidden' }}>
      {
        
      }
      <QrReader
        constraints={{facingMode: "environment", fps: 10}}
        onResult={(result, error) => {
          console.log(result)
          if (!!result) {
            window.Telegram.WebApp.sendData(result.text)
          }

          if (!!error) {
            console.info(error)
          }
        }}
        containerStyle={{
          width: '100%',
          height: '100dvh',
          zIndex: '2',
          position: 'absolute',
        }}
        videoContainerStyle={{
          width: '100%',
          height: '100dvh',
          background: 'black',
        }}
        videoStyle={{
          width: '100%',
          height: '100dvh',
          transform: 'scale(1.5)',
        }}
      />
      <div className="container-scanner">
        <div
          className="borders"
          style={{
            boxShadow: '0 0 200px 200px rgba(0, 0, 0, 0.2)',
            top: borderWidthTop,
            bottom: borderWidthBottom,
            right: borderWidth,
            left: borderWidth,
            width: '253px',
            height: '254px',
            borderRadius: '35px',
          }}> <Border className='border'/> </div>
        
      </div>

      <div className="container">
        <div className="content">
          <h1 className="title">Сканируйте QR код автомата</h1>
        </div>
        <div className="container-buttons">
          <Button
            variant="transparent"
            size="large"
            style={{ fontSize: '16px', width: '100%' }}
            onClick={handleQr}
          >
            Не сканирует QR-код?
          </Button>
        </div>
      </div>
    </div>
  )
}
