export const Scanner = ({ props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.35352 14.1384C6.35352 13.8913 6.55386 13.6909 6.80101 13.6909H14.8558C15.103 13.6909 15.3033 13.8913 15.3033 14.1384C15.3033 15.3741 14.3016 16.3759 13.0659 16.3759H8.59097C7.35526 16.3759 6.35352 15.3741 6.35352 14.1384Z"
      fill="#33373C"
    />
    <path
      d="M6.35352 7.87368C6.35352 6.63797 7.35526 5.63623 8.59097 5.63623H13.0659C14.3016 5.63623 15.3033 6.63797 15.3033 7.87368V11.4536C15.3033 11.7008 15.103 11.9011 14.8558 11.9011H6.80101C6.55386 11.9011 6.35352 11.7008 6.35352 11.4536V7.87368Z"
      fill="#33373C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.87891 11.0058C1.87891 10.5115 2.2796 10.1108 2.77389 10.1108H18.8836C19.3778 10.1108 19.7785 10.5115 19.7785 11.0058C19.7785 11.5001 19.3778 11.9008 18.8836 11.9008H2.77389C2.2796 11.9008 1.87891 11.5001 1.87891 11.0058Z"
      fill="#33373C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.87891 4.7411C1.87891 3.25824 3.081 2.05615 4.56385 2.05615H6.35381C6.8481 2.05615 7.24879 2.45685 7.24879 2.95113C7.24879 3.44542 6.8481 3.84612 6.35381 3.84612H4.56385C4.06957 3.84612 3.66887 4.24681 3.66887 4.7411V6.53106C3.66887 7.02534 3.26817 7.42604 2.77389 7.42604C2.2796 7.42604 1.87891 7.02534 1.87891 6.53106V4.7411Z"
      fill="#33373C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.87891 17.2706C1.87891 18.7535 3.081 19.9556 4.56385 19.9556H6.35381C6.8481 19.9556 7.24879 19.5549 7.24879 19.0606C7.24879 18.5663 6.8481 18.1656 6.35381 18.1656H4.56385C4.06957 18.1656 3.66887 17.7649 3.66887 17.2706V15.4807C3.66887 14.9864 3.26817 14.5857 2.77389 14.5857C2.2796 14.5857 1.87891 14.9864 1.87891 15.4807V17.2706Z"
      fill="#33373C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7783 17.2706C19.7783 18.7535 18.5762 19.9556 17.0934 19.9556H15.3034C14.8091 19.9556 14.4084 19.5549 14.4084 19.0606C14.4084 18.5663 14.8091 18.1656 15.3034 18.1656H17.0934C17.5877 18.1656 17.9884 17.7649 17.9884 17.2706V15.4807C17.9884 14.9864 18.3891 14.5857 18.8833 14.5857C19.3776 14.5857 19.7783 14.9864 19.7783 15.4807V17.2706Z"
      fill="#33373C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7783 4.7411C19.7783 3.25824 18.5762 2.05615 17.0934 2.05615H15.3034C14.8091 2.05615 14.4084 2.45685 14.4084 2.95113C14.4084 3.44542 14.8091 3.84612 15.3034 3.84612H17.0934C17.5877 3.84612 17.9884 4.24681 17.9884 4.7411V6.53106C17.9884 7.02534 18.3891 7.42604 18.8833 7.42604C19.3776 7.42604 19.7783 7.02534 19.7783 6.53106V4.7411Z"
      fill="#33373C"
    />
  </svg>
)
