export const Border = ({props}) => (
    <svg width="255" height="255" viewBox="0 0 255 255" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g filter="url(#filter0_b_836_2540)">
<path d="M64.4478 1.88672H59.0779C39.0284 1.88672 29.0037 1.88672 21.3458 5.7886C14.6097 9.2208 9.13315 14.6974 5.70096 21.4334C1.79907 29.0913 1.79907 39.1161 1.79907 59.1655V64.5354M189.745 1.88672H195.115C215.165 1.88672 225.189 1.88672 232.847 5.7886C239.583 9.2208 245.06 14.6974 248.492 21.4334C252.394 29.0913 252.394 39.1161 252.394 59.1655V64.5354M252.394 189.833V195.203C252.394 215.252 252.394 225.277 248.492 232.935C245.06 239.671 239.583 245.147 232.847 248.58C225.189 252.482 215.165 252.482 195.115 252.482H189.745M64.4478 252.482H59.0779C39.0284 252.482 29.0037 252.482 21.3458 248.58C14.6097 245.147 9.13315 239.671 5.70096 232.935C1.79907 225.277 1.79907 215.252 1.79907 195.203V189.833" stroke="white" stroke-width="3.57993" stroke-linecap="round"/>
</g>
<defs>
<filter id="filter0_b_836_2540" x="-35.7902" y="-35.7026" width="325.773" height="325.773" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="17.8996"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_836_2540"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_836_2540" result="shape"/>
</filter>
</defs>
</svg>
)