export const Headset = ({ props }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M36.4627 19.3502V31.6562C36.4586 33.2868 35.809 34.8495 34.656 36.0025C33.503 37.1556 31.9403 37.8051 30.3097 37.8092H21.4647C21.1383 37.8092 20.8253 37.6795 20.5946 37.4488C20.3638 37.218 20.2341 36.905 20.2341 36.5786C20.2341 36.2522 20.3638 35.9392 20.5946 35.7084C20.8253 35.4776 21.1383 35.348 21.4647 35.348H30.3097C31.2875 35.3439 32.2242 34.9537 32.9157 34.2622C33.6071 33.5708 33.9974 32.6341 34.0015 31.6562V31.4408C33.6068 31.5832 33.1904 31.6561 32.7709 31.6562H30.3097C29.3305 31.6562 28.3915 31.2672 27.6992 30.5749C27.0068 29.8825 26.6179 28.9435 26.6179 27.9644V21.8114C26.6179 20.8323 27.0068 19.8933 27.6992 19.2009C28.3915 18.5086 29.3305 18.1196 30.3097 18.1196H33.9399C33.6314 14.7389 32.0639 11.5975 29.5481 9.31814C27.0323 7.03882 23.7519 5.78799 20.3572 5.81361H20.3418C16.9451 5.78085 13.6608 7.02923 11.1435 9.30994C8.62615 11.5906 7.06067 14.7362 6.75906 18.1196H10.3893C11.3672 18.1237 12.3039 18.5139 12.9953 19.2054C13.6868 19.8969 14.0771 20.8335 14.0811 21.8114V27.9644C14.0771 28.9423 13.6868 29.879 12.9953 30.5704C12.3039 31.2619 11.3672 31.6521 10.3893 31.6562H7.92813C6.95025 31.6521 6.01358 31.2619 5.3221 30.5704C4.63063 29.879 4.24038 28.9423 4.23633 27.9644V19.3502C4.23628 17.2403 4.65362 15.1511 5.46431 13.2031C6.27499 11.2551 7.46302 9.48677 8.95998 7.99982C10.4569 6.51287 12.2332 5.33673 14.1866 4.53913C16.14 3.74152 18.2319 3.33821 20.3418 3.35241H20.4649C24.7065 3.35649 28.7732 5.04327 31.7725 8.04256C34.7718 11.0418 36.4586 15.1086 36.4627 19.3502V19.3502Z"
      fill="#6433C4"
    />
  </svg>
)
