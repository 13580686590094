export const Headphones = ({ props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.515 9.52558V16.2379C18.5128 17.1274 18.1585 17.9797 17.5296 18.6087C16.9006 19.2376 16.0483 19.5919 15.1588 19.5941H10.3343C10.1563 19.5941 9.98556 19.5234 9.85968 19.3975C9.7338 19.2716 9.66308 19.1009 9.66308 18.9229C9.66308 18.7449 9.7338 18.5741 9.85968 18.4483C9.98556 18.3224 10.1563 18.2517 10.3343 18.2517H15.1588C15.6922 18.2494 16.2031 18.0366 16.5803 17.6594C16.9575 17.2822 17.1703 16.7713 17.1725 16.2379V16.1205C16.9573 16.1981 16.7301 16.2379 16.5013 16.2379H15.1588C14.6248 16.2379 14.1126 16.0258 13.7349 15.6481C13.3573 15.2705 13.1451 14.7583 13.1451 14.2242V10.8681C13.1451 10.334 13.3573 9.82179 13.7349 9.44415C14.1126 9.06651 14.6248 8.85435 15.1588 8.85435H17.139C16.9707 7.01031 16.1157 5.29682 14.7434 4.05355C13.3712 2.81028 11.5819 2.12801 9.7302 2.14199H9.72181C7.86907 2.12412 6.07764 2.80505 4.70455 4.04907C3.33146 5.29309 2.47756 7.00884 2.31305 8.85435H4.29319C4.82658 8.85656 5.33749 9.06942 5.71466 9.44659C6.09182 9.82375 6.30469 10.3347 6.3069 10.8681V14.2242C6.30469 14.7576 6.09182 15.2685 5.71466 15.6457C5.33749 16.0229 4.82658 16.2357 4.29319 16.2379H2.95072C2.41733 16.2357 1.90642 16.0229 1.52925 15.6457C1.15209 15.2685 0.93922 14.7576 0.937012 14.2242V9.52558C0.936986 8.3747 1.16462 7.23518 1.60682 6.17264C2.04901 5.1101 2.69703 4.14552 3.51355 3.33446C4.33007 2.5234 5.29897 1.88187 6.36445 1.44681C7.42994 1.01176 8.57096 0.791768 9.72181 0.799514H9.78894C12.1026 0.801736 14.3208 1.7218 15.9567 3.35777C17.5927 4.99375 18.5128 7.21197 18.515 9.52558V9.52558Z"
      fill="#1A1D1F"
    />
  </svg>
)
