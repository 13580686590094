import './button.css'
import React from 'react'

export default class Button extends React.Component {
  render() {
    const {
      size,
      variant,
      text,
      children,
      type,
      icon,
      onClick,
      style,
      disabled,
    } = this.props
    return (
      <button
        type={type}
        className={`btn-size-${size} btn-variant-${variant || 'default'}`}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {variant === 'loading' && <span className="loader" />}
        <span className="icon">{icon}</span>

        {variant !== 'loading' && (
          <span className="btn-text">{text || children}</span>
        )}
      </button>
    )
  }
}
