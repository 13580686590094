import styles from './codeNumber.module.css'
import Button from '../ui/button/button'
import { Scanner } from '../ui/icons/scanner'
import { useEffect, useState } from 'react'
import { Arrow } from '../ui/icons/arrow'
import { Headphones } from '../ui/icons/headphones'
import { Headset } from '../ui/icons/Headset'

export const CodeNumber = ({ handleQr }) => {
  const [number, setNumber] = useState('')
  const [isNotFound, setNotFound] = useState(false)
  const [isModal, setModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    async function amplitudeTracking(){
      const queryParams = new URLSearchParams(window.location.search)
      const phone = queryParams.get("phone")
      const eventProperties = {
        phone: phone,
        telegram_user_id : phone
      };
      let amplitudePromise = window.amplitude.track({event_type : 'rentbot_vending_number_input_screen', event_properties : eventProperties,
        user_id : phone, session_id : +phone
      }).promise
      amplitudePromise.then((e => console.log(e)), (e => console.log(e)));
    }
    amplitudeTracking()
  }, [])

  const handleInput = (value) => {
    if (value.length <= 5) {
      setNumber(value)
    }
  }

  const sendData = () => {
    window.Telegram.WebApp.sendData(number)
    // if (data.includes(number)) {
    //   setLoading(false)
    //   console.log(isLoading)
    // } else {
    //   setNotFound(true)
    //   console.log(isLoading)
      
    //   setTimeout(() => {
    //     setNotFound(false)
    //     setModal(true)
    //   }, 1000)

    // }
    console.log(isLoading)
  }

  function afterSubmit(event) {
    event.preventDefault()
    
  }
  function onSupport(){
    window.Telegram.WebApp.sendData('support')
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      event.preventDefault()
      sendData()
      setLoading(true)
    }
  };

  return (
    <div>
      <form onSubmit={afterSubmit} className={styles.container}>
        <div className={styles.containerInput}>
          <div className={styles.nav}>
            <a href="/">
              <Arrow />
            </a>
            {isNotFound && (
              <div className={styles.notFound}>Не нашли такой автомат</div>
            )}
            <button style={{    background: "none",
    outline: "none",
    border: "none",}} onClick={onSupport}>
              <Headphones />
            </button>
          </div>
          <h1 className={styles.title}>Введите номер автомата</h1>
          <p className={styles.text}>
            номер написан на автомате прямо под QR-кодом
          </p>
          <input
            type="number"
            className={styles.input}
            value={number}
            onChange={(event) => handleInput(event.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div className={styles.containerButtons}>
          <Button variant="icon" icon={<Scanner />} onClick={handleQr} />

          <Button
            variant={
              isLoading ? 'loading' : number.length < 4 ? 'disabled' : 'default'
            }
            disabled={number.length < 4}
            size="large"
            onClick={() => {
              sendData()
              setLoading(true)
            }}
          >
            Далее
          </Button>
        </div>
      </form>
      {isModal && (
        <div className={styles.modalContainer}>
          <div className={styles.modal}>
            <span
              className={styles.modalClose}
              onClick={() => {
                setLoading(false)
                setModal(false)
                setNotFound(false)
              }}
            >
              &times;
            </span>
            <span className={styles.icon}>
              <Headset />
            </span>
            <p className={styles.modalTitle}>Попробуйте снова</p>
            <p className={styles.modalText}>
              Номер автомата это 4-5 цифр прямо под QR-кодом. Убедитесь, что
              номер введен правильно. Всё равно не работает? Напишите в
              Поддержку — мы поможем
            </p>
            <Button
              style={{
                height: '50px',
                borderRadius: '15px',
                margin: 'auto',
                width: '90%',
              }}
              onClick={onSupport}
            >
              Поддержка
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
