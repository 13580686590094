import { useEffect, useState } from 'react'
import { ReactDOM } from 'react'
import { CodeNumber } from './components/code-number/CodeNumber'
import { QrReaderContainer } from './components/qr-reader/QrReaderContainer'

function App() {
  const [isEnabledQr, setIsEnabledQr] = useState(true)
  useEffect(() => {
    
    async function amplitudeTracking(){
      const queryParams = new URLSearchParams(window.location.search)
      const phone = queryParams.get("phone")
      window.amplitude.setSessionId(+phone)
      const eventProperties = {
        phone: phone,
        telegram_user_id : phone
      };
      let amplitudePromise = window.amplitude.track({event_type : 'rentbot_scan_qr', event_properties : eventProperties,
         user_id : phone, session_id : +phone}).promise
      amplitudePromise.then((e => console.log(e)), (e => console.log(e)));
    }
    amplitudeTracking()
  }, [])
  function handlerQr() {
    try {
      setIsEnabledQr(!isEnabledQr)
    } catch(err) {
      console.log(err)
    }
  }

  return (
    <>
      {isEnabledQr ? (
        <QrReaderContainer  handleQr={handlerQr}  isEnabled={isEnabledQr}/>
      ) : ( 
        <CodeNumber handleQr={handlerQr} />
      )}
    </>
  )
}

export default App
